<template>
  <div>
    <!-- dialog duyệt giảng viên -->
    <b-modal
      id="handle-approve-candidate"
      title="Duyệt giảng viên chính thức"
      size="lg"
      ok-only
      centered
      hide-footer>
      <h4 v-if="teacher.approved">
        Ứng viên {{ teacher.name }} đã được duyệt thành giảng viên chính thức.
      </h4>
      <div v-else>
        <p>Giảng viên <span style="font-weight: bold">{{ teacher.name }}</span> đã hoàn thành đào tạo.
          Vui lòng duyệt để giảng viên có thể bắt đầu quá trình giảng dạy.</p>
        <p>Trước khi duyệt, vui lòng chọn đúng nhóm mà giảng viên đã được phân công.</p>
        <div>
          <label for="">Gán giảng viên vào nhóm</label>
          <el-select class="w-100 shadow-sm" v-model="group_id" placeholder="Chọn trạng thái" collapse-tags
                     clearable>
            <el-option v-for="item in listTeacherGroup" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="mt-8">
        <el-button
          :disabled="loading_approve"
          native-type="submit"
          size="small"
          class="float-right text-white rounded"
          style="background-color: rgba(93, 4, 176, 1);"
          @click="handleOk"
          v-if="!teacher.approved"
        >
          <i v-bind:class="[loading_approve ? 'el-icon-loading' : '']"></i>
          Xác nhận
        </el-button>
      </div>
      <div>
        <el-button
          style="background-color: rgba(202, 202, 202, 1); padding: 11px 20px"
          size="small"
          class="float-right mr-3 rounded"
          @click="closeModal"
        >
          Huỷ bỏ
        </el-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { APPROVE_CANDIDATE_TEACHER } from "../../../../core/services/store/course/classes.module";
import {GET_TEACHER_GROUPS} from "@/core/services/store/teacher/teacher.module";

export default {
  name: "ApproveCandidate",
  components: {},
  props: {
    teacher: Object,
  },
  mounted() {
    this.getTeacherGroups();
  },
  data() {
    return {
      loading_approve: false,
      group_id: '',
      listTeacherGroup: []
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    handleOk() {
      this.callApi();
    },

    callApi() {
      this.loading_approve = true;
      this.$store
        .dispatch(APPROVE_CANDIDATE_TEACHER, {
          id: this.teacher.id,
          group_id: this.group_id
        })
        .then((res) => {
          this.loading_export = false;
          this.$notify({
            title: "Thành công",
            message: "Duyệt giảng viên thành công!",
            type: "success",
          });
          this.loading_approve = false;
          this.closeModal();
        }).catch((e) => {
            this.$notify({
               title: "Lỗi",
               message: "Đã xảy ra lỗi khi duyệt giảng viên.",
               type: "error",
            });
        }).finally(() => {
        });
    },
    closeModal() {
      this.$bvModal.hide("handle-approve-candidate");
      this.$emit("reload");
    },
    getTeacherGroups(){
      this.$store.dispatch(GET_TEACHER_GROUPS).then((data)=>{
        this.listTeacherGroup = data.data
        console.log(this.listTeacherGroup, 'a')
      })
    },
  },
};
</script>

<style>
#handle-approve-candidate {
  top: -100px;
}
</style>
